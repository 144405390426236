<template>
  <b-modal
    visible
    scrollable
    centered
    size="lg"
    hide-footer
    no-close-on-backdrop
    @hidden="onClose"
    @ok="onClose"
  >
    <PatientDetailChat
      :patient="patient"
      :chat-appeal-id="appeal?.chatAppealId"
      :modal-name="modalName"
    />
  </b-modal>
</template>
<script>
import PatientDetailChat from '@/components/Patients/PatientDetailChat.vue';

export default {
  name: 'PatientAppealChatModal',
  components: {
    PatientDetailChat,
  },
  props: {
    modalName: {
      type: [String, Number],
      default: null,
    },
    patient: {
      type: Object,
      default: () => ({}),
    },
    appeal: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      isOpen: true,
    };
  },
  methods: {
    onClose() {
      this.$emit('input', false);
    },
  },
};
</script>
